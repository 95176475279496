<template>

  <div class="regin">

    <div class="backHome ">
      <span class="backIco" @click="back('prev')"></span>
      <span class="backHomeIco" @click="back('main')"></span>
      房产信息
    </div>
    <!-- <div class="title">房产信息</div> -->
    <div class="line">
      <span class="lable">业务类型<i class="must_input">*</i>:</span>
      <span style="margin-left: 90px">
        <select title="业务类型" v-model="typeid">
          <option value="0" selected>出售</option>
          <option value="1">出租</option>
        </select>
      </span>
    </div>
    <div class="line">
      <span class="lable">小区名称<i class="must_input">*</i>:</span>
      <input type="text" name="" v-model="housename" id="housename" ref="housename" />
    </div>
    <div class="line">
      <span class="lable">户型<i class="must_input">*</i>:</span>
      <!--  <input type="text" /> -->
      <!--      <select placeholder="阳台" v-model="this.sex" @change="selectsexchange">
       <option  v-for="option in options" :value="option.value" >
         {{ option.text }}
       </option>
      </select> -->
      <!--      <select placeholder="阳台" v-model="this.sex" @change="selectsexchange">
       <option  v-for="option in options" :value="option.value" >
         {{ option.text }}
       </option>
      </select> -->
      <span style="margin-left: 90px">
        <select title="室" v-model="room">
          <option value="1">1室</option>
          <option value="2">2室</option>
          <option value="3">3室</option>
          <option value="4">4室</option>
          <option value="5">5室</option>
        </select>
        <select title="厅" v-model="hall">
          <option value="0">0厅</option>
          <option value="1">1厅</option>
          <option value="2">2厅</option>
          <option value="3">3厅</option>
          <option value="4">4厅</option>
          <option value="5">5厅</option>
        </select>
        <select title="卫" v-model="toilet">
          <option value="1">1卫</option>
          <option value="2">2卫</option>
          <option value="3">3卫</option>
          <option value="4">4卫</option>
          <option value="5">5卫</option>
        </select>
        <select title="阳台" v-model="balcony">
          <option value="0">0阳</option>
          <option value="1">1阳</option>
          <option value="2">2阳</option>
          <option value="3">3阳</option>
          <option value="4">4阳</option>
          <option value="5">5阳</option>
        </select>
      </span>
    </div>
    <div class="line">
      <span class="lable" v-if="typeid == 0">总价(万元)<i class="must_input">*</i>:</span>
      <span class="lable" v-else>月租(元)<i class="must_input">*</i>:</span>
      <input type="number" name="" v-model="price" @blur="priceBlur" id="price" ref="price" placeholder="价格"
        oninput="if(value.length>11)value=value.slice(0,11)" @keydown="checkInput($event)" />
    </div>
    <div class="line">
      <span class="lable">面积(㎡)<i class="must_input">*</i>:</span>
      <input class="text" type="number" name="" v-model="houseearm" id="houseearm" ref="houseearm" placeholder="面积(平方米)"
        oninput="if(value.length>11)value=value.slice(0,11)" @keydown="checkInput($event)" />
    </div>
    <!--    <div class="line">
      <span class="lable">标题<i class="must_input"></i>:</span>
      <input class="text" type="text" name="" v-model="title" id="title" ref="title" placeholder="标题" />
    </div> -->
    <div class="line">
      <span class="lable">物业类型<i class="must_input">*</i>:</span>
      <span style="margin-left: 90px">
        <select title="物业类型" v-model="catid">
          <option value="1">住宅</option>
          <option value="2">公寓</option>
          <option value="3">商铺</option>
          <option value="4">写字楼</option>
          <option value="5">厂房</option>
          <option value="6">农民房</option>
          <option value="7">其他</option>
        </select>
      </span>
    </div>
    <div class="line">
      <span class="lable">装修标准<i class="must_input">*</i>:</span>
      <span style="margin-left: 90px">
        <select title="装修标准" v-model="fitup">
          <option value="1">不限</option>
          <option value="2">毛坯</option>
          <option value="3">精装</option>
          <option value="4">简装</option>
          <option value="5">豪华装修</option>
        </select>
      </span>
    </div>
    <div class="line">
      <span class="lable">地区<i class="must_input">*</i>:</span>
      <input type="text" placeholder="请选择" name="" v-model="houseArea" @click="chooseHouseArea=true;" />
      <!--      <span style="margin-left: 90px">
        <select title="室" >
          <option value="1">江西省</option>
        </select>
        <select title="室" >
          <option value="1">瑞昌市</option>
        </select>
        <select title="室" >
          <option value="1">桂林</option>
          <option value="2">湓城</option>
        </select>
      </span>-->
    </div>
    <div class="line" style="display:none;">
      <span class="lable">地址<i class="must_input"></i>:</span>
      <input type="text" name="" v-model="address" id="address" ref="address" placeholder="地址" />
    </div>
    <div class="line">
      <span class="lable">朝向<i class="must_input">*</i>:</span>
      <span style="margin-left: 90px">
        <select title="朝向" v-model="toward">
          <option value="1">东</option>
          <option value="2">西</option>
          <option value="3">南</option>
          <option value="4">北</option>
          <option value="5">东南</option>
          <option value="6">西南</option>
          <option value="7">南北</option>
          <option value="8">东北</option>
          <option value="9">西北</option>
          <option value="10">东西</option>
        </select>
      </span>
    </div>
    <div class="line">
      <span class="lable">房龄<i class="must_input">*</i>:</span>
      <span style="margin-left: 90px">
        <select title="房龄" v-model="houseyear">
          <option value="0">不详</option>
          <option value="2022">2022年</option>
          <option value="2021">2021年</option>
          <option value="2020">2020年</option>
          <option value="2019">2019年</option>
          <option value="2018">2018年</option>
          <option value="2017">2017年</option>
          <option value="2016">2016年</option>
          <option value="2015">2015年</option>
          <option value="2014">2014年</option>
          <option value="2013">2013年</option>
          <option value="2012">2012年</option>
          <option value="2011">2011年</option>
          <option value="2010">2010年</option>
          <option value="2009">2009年</option>
          <option value="2008">2008年</option>
          <option value="2007">2007年</option>
          <option value="2006">2006年</option>
          <option value="2005">2005年</option>
          <option value="2004">2004年</option>
          <option value="2003">2003年</option>
          <option value="2002">2002年</option>
          <option value="2001">2001年</option>
          <option value="2000">2000年</option>
        </select>
      </span>
    </div>
    <div class="line">
      <span class="lable">所在楼层<i class="must_input">*</i>:</span>
      <input type="text" name="" v-model="floor1" id="floor1" ref="floor1"
        oninput="if(value.length>4)value=value.slice(0,4)" @keydown="checkInput($event)" />
    </div>
    <div class="line">
      <span class="lable">总楼层<i class="must_input">*</i>:</span>
      <input type="text" name="" v-model="floor2" id="floor2" ref="floor2"
        oninput="if(value.length>4)value=value.slice(0,4)" @keydown="checkInput($event)" />
    </div>
    <div class="line">
      <span class="lable">详细说明</span>
      <!-- <input type="text" name="" v-model="content" id="content" ref="content" /> -->
    </div>
    <div class="line" style="height: 34.4vw;">
      <textarea style="width: 100%; border: 0" rows="6" v-model="content" id="content" ref="content" autosize
        type="textarea" placeholder="请描述一下您的房屋" />
    </div>
    <div class="line">
      <span class="lable">房屋图片</span>
    </div>
    <div>
      <div class="flex flex__middle" @click.stop="uploadHeadImg">
        <!-- round width="3rem" height="3rem" -->
        <van-image :src="imgurl" style="width:360px;height:270px;" />
        <img class="icon28" src="../../assets/img/iconfonti.png" />
      </div>
      <input type="file" accept="image/*" @change="handleFile" class="hiddenInput" style="display: none" />
    </div>
    <!--   <div class="setting_right" @click.stop="uploadHeadImg">
            <div class="caption">更改头像</div>
          </div> -->

    <div class="bottomBtn" v-if="status != '4' && status != '5'" @click="save()">保存信息</div>


    <!--    <div class="line" style="position: absolute;" v-if="type == 'edit' &&status =='3' ">
      <span class="lable">下架原因</span>
      <span style="margin-left: 90px">
        <select title="室">
          <option value="0">不想出售</option>
          <option value="1">房屋已售出</option>
          <option value="2">房产本渠道成交</option>
        </select>
      </span>
    </div> -->

    <!--  <div v-if="type == 'edit' && status =='3'" class="bottomBtn"
      style="margin-bottom: 1px;position:absolute;width: 30vw;margin-left: 250px;margin-top: 0px;" @click="not_sell()">
      房屋已售出（出租）
    </div> -->
    <div class="line" v-if="type == 'edit'">
      <span class="lable">状态描述:{{keyword}}</span>

    </div>

    <div v-if="type == 'edit' && status =='3' && typeid =='1'" class="bottomBtn" @click="not_sell(51)">
      房屋已出租
    </div>
    <div v-if="type == 'edit' && status =='3' && typeid =='0'" class="bottomBtn" @click="not_sell(52)">
      房屋已售出
    </div>

    <div v-if="type == 'edit' && status =='3' && typeid =='1'" class="bottomBtn" @click="not_sell(41)">
      我不想出租
    </div>
    <div v-if="type == 'edit' && status =='3' && typeid =='0'" class="bottomBtn" @click="not_sell(42)">
      我不想出售
    </div>
    <div v-if="type == 'edit' && status !='3' && keyword !='已售出' && keyword !='已租出'" class="bottomBtn"
      @click="on_sell()">
      再次上架
    </div>

    <!--
    <div v-if="type == 'edit' && status =='3'" class="bottomBtn" @click="to_select_user()">
      房产成交（本渠道）
    </div> -->


    <van-popup v-model="chooseHouseArea" round position="bottom">
      <van-cascader v-model="chooseHouseArea" title="请选择城市,点×可以完成选择" :options="quxianJson"
        @close="chooseHouseArea = false;" @change="chooseHouseAreaChange" @finish="chooseHouseArea = false;" />
    </van-popup>
  </div>
</template>

<script>
  import {
    RadioGroup,
    Radio,
    DatetimePicker,
    Popup,
    Cascader,
    Toast
  } from "vant";
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from "js-cookie";
  import {
    getQueryVariable
  } from "@/utils/request";
  import axios from "axios";
  import qs from "qs";
  import area from "@/utils/4-area.json";

  export default {
    name: "regin",
    props: {},
    data() {
      return {
        quxianJson: [],
        houseArea: '', //这个房屋所在的具体的街道等
        chooseHouseArea: false,
        type: "", //新增还是修改 add edit
        id: "",
        userCode: "",
        housename: "", //小区名称
        title: "",
        imgurl: "https://mall.5izg.cn/images/noneimg.jpg",
        priceRegex: /^(([1-9]\d*)(\.\d{1,4})?)$|(0\.\d{1,4})$/,
        price: "",
        room: "",
        hall: "",
        toilet: "",
        balcony: "",
        houseearm: "",
        adddate: "",
        houseyear: "", //房屋年龄
        floor1: "", //几楼
        floor2: "", //共几层
        editdate: "", //修改日期
        address: "",
        content: "", //详情
        toward: "", //朝向
        catid: "", //物业类型
        typeid: "0", //1-出租还是0-出售
        status: '', //3上架  4下架
        fitup: '', //装修
        keyword: '', //下架原因
      };
    },
    components: {},
    mounted() {
      //判断一下，cookie如果丢失了，那么就重新登录
      if (Cookies.get("kh") == "" || Cookies.get("kh") == null) {
        //如果获取用户信息失败那就直接跳到登录
        this.$router.push("login");
        return;
      }
      this.getWxConfig(); //加载js
      this.quxianJson = this.parseArea();
      this.userCode = Cookies.get("kh");
      let url = location.href;
      let houseid = decodeURI(getQueryVariable(url, "id"));
      console.log(houseid);
      if (
        houseid == "" ||
        houseid == null ||
        houseid == "null" ||
        houseid == "undefined" ||
        houseid == undefined
      ) {
        this.type = "add";
      } else {
        //不传值就不需要加载
        //读出配置信息
        this.type = "edit"; //修改状态下,我不想卖按钮可见
        this.getHouseDetails(houseid);
      }
      // document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
      //   // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
      //   WeixinJSBridge.call('hideOptionMenu');
      // });
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      priceBlur() {
        if (!this.price || !this.priceRegex.test(this.price) || this.price <= 0) {
          Toast('总价输入错误');
        }
      },
      parseArea() {
        //console.log(area);
        //console.log(area instanceof Array);
        let areaJs = JSON.parse(JSON.stringify(area));
        //console.log(areaJs instanceof Array);
        let result = areaJs.map(item => {
          return {
            text: item.pro_name,
            value: item.pro_code,
            children: item.pro_cities.map(e => {
              return {
                text: e.city_name,
                value: e.city_code,
                children: e.city_areas.map(val => {
                  return {
                    text: val.area_name,
                    value: val.area_code,
                    children: val.area_streets.map(t => {
                      return {
                        text: t.street_name,
                        value: t.street_code
                      }
                    })
                  }
                })
              }
            })
          }
        })
        return result;
      },
      chooseHouseAreaChange({
        selectedOptions
      }) {
        let areaName = selectedOptions.map((option) => option.text).join('/');
        let areaCode = selectedOptions[selectedOptions.length - 1].value;
        console.log(areaName);
        console.log(areaCode);
        this.houseArea = areaName;
      },
      back(where) {
        //顶部后退和返回按钮
        if (where == "main") {
          this.$router.push({
            name: "main",
          });
        }
        if (where == "prev") {
          this.$router.back();
        }
      },
      onFinish({
        selectedOptions
      }) {
        this.nationActive = false;
        this.nationText = selectedOptions.map((option) => option.text).join("/");
      },
      // 打开图片上传
      uploadHeadImg: function() {
        this.$el.querySelector(".hiddenInput").click();
      },
      // 将头像显示
      handleFile: function(e) {
        let $target = e.target || e.srcElement;
        let file = $target.files[0];
        var reader = new FileReader();
        reader.onload = (data) => {
          let res = data.target || data.srcElement;
          this.headimg = res.result;
        };

        const isImg = file.type === "image/jpeg" || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 10;

        if (!isImg) {
          Toast("上传图片只能是 JPG/JPEG/PNG 格式!");
          return false;
        }
        if (!isLt2M) {
          Toast("上传图片大小不能超过 4MB! 当前文件大小:" + file.size);
          return false;
        }
//https://blog.csdn.net/sunddy_x/article/details/123528350
//https://blog.csdn.net/qq_31001061/article/details/106075663?utm_medium=distribute.pc_relevant.none-task-blog-2~default~baidujs_baidulandingword~default-1-106075663-blog-123528350.pc_relevant_aa&spm=1001.2101.3001.4242.2&utm_relevant_index=4
        reader.readAsDataURL(file);

        //直接调用上传接口，将图片传给后台
        //要考虑文件加载时间,先做了一个延时
        setTimeout(() => {
          //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
          this.uploadImg(file);
          //延迟时间：秒
        }, 500);
      },
      //通过接口传图片
      async uploadImg(file) {
        var _this = this;
        let file1 = this.headimg; //获取文件数据
        var arr = [];
        arr.push({
          imgurl: file1,
        });
        console.log(arr);
        var imglist = JSON.stringify(arr);
        var uri = "";
        uri = this.$global.domain + "/tools/mallsubmit.ashx";
        var param = {
          action: "saveImglist",
          user: Cookies.get("kh"),
          type: 1, //1 传照片  2传头像
          imglist: imglist,
          sitenum: "18777777777",
          token: Cookies.get("token"), //验证用户合法性
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //
          _this.imgurl = result.data;
          Toast("上传成功,保存后生效");
          //location.reload(); //先刷新吧
        } else {
          Toast(result.msg);
        }
      },
      async save() {
        //保存本次修改的房屋信息
        let url = location.href;
        let type = decodeURI(getQueryVariable(url, "type"));

        //判断必填项目
        if (this.housename == '') {
          Toast('小区名称必填');
          return;
        }

        if (this.room == '') {
          Toast('户型几室必填');
          return;
        }
        if (this.hall == '') {
          Toast('户型几厅必填');
          return;
        }

        if (!this.price || !this.priceRegex.test(this.price) || this.price <= 0) {
          Toast('总价输入错误');
          return;
        }

        if (!this.houseearm || !this.priceRegex.test(this.houseearm) || this.houseearm <= 0) {
          Toast('面积输入错误');
          return;
        }

        // toilet: that.toilet,
        // balcony: that.balcony,
        if (this.houseearm == '') {
          Toast('面积必填');
          return;
        }
        if (this.catid == '') {
          Toast('物业类型必填');
          return;
        }

        if (this.fitup == '') {
          Toast('装修必填');
          return;
        }

        if (this.houseArea == '') {
          Toast('地区必填');
          return;
        }
        if (this.toward == '') {
          Toast('朝向必填');
          return;
        }

        if (this.houseyear == '') {
          Toast('房龄必填');
          return;
        }

        if (this.floor1 == '') {
          Toast('所在楼层必填');
          return;
        }
        if (!this.floor1 || !this.priceRegex.test(this.floor1) || this.floor1 <= 0) {
          Toast('所在楼层输入错误,必须输入数字');
          return;
        }


        if (this.floor2 == '') {
          Toast('总楼层必填');
          return;
        }
        if (!this.floor2 || !this.priceRegex.test(this.floor2) || this.floor2 <= 0) {
          Toast('总楼层输入错误,必须输入数字');
          return;
        }


        let act = "updateHouseDetails";
        if (type == "add") {
          //如果是新增,走新增接口
          act = "addHouseDetails";
        }
        var that = this;
        var uri = this.$global.domain + "/tools/postmysql.ashx";
        var param = {
          act: act,
          user: Cookies.get("kh"),
          id: that.id, //房屋id
          housename: that.housename, //小区名称
          title: that.title,
          imgurl: that.imgurl,
          price: that.price,
          room: that.room,
          hall: that.hall,
          toilet: that.toilet,
          balcony: that.balcony,
          houseearm: that.houseearm,
          adddate: that.adddate,
          houseyear: that.houseyear, //房屋年龄
          floor1: that.floor1, //第几楼
          floor2: that.floor2, //共几层
          editdate: that.editdate, //修改日期
          address: that.address,
          content: that.content, //详情
          toward: that.toward, //朝向
          catid: that.catid, //物业类型
          typeid: that.typeid, //出租还是出售
          fitup: that.fitup, //装修
          houseArea: that.houseArea, //这个房屋的具体省市区县街道
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        let result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          Toast(result.msg);
          //延时跳转 让顾客看清提示
          setTimeout(() => {
            //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
            this.$router.push("my_house_list");
            //延迟时间：3秒
          }, 500);
        } else {
          Toast(result.msg);
        }
      },
      async getHouseDetails(houseid) {
        //获取自己创建的房屋信息

        var that = this;
        let uri = this.$global.domain + "/tools/postmysql.ashx";
        let param = {
          act: "getHouseDetails",
          id: houseid, //本次查询的房屋编号
          user: Cookies.get("kh"),
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        let result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          let data = result.data;
          that.id = data[0][0];
          that.imgurl = data[0][2];
          that.title = data[0][1];
          that.add_date = data[0][9];
          that.price = data[0][3];
          that.houseearm = data[0][8];
          that.room = data[0][4];
          that.hall = data[0][5];
          that.toilet = data[0][6];
          that.balcony = data[0][7];
          that.content = data[0][10]; //详情
          that.housename = data[0][11];
          that.address = data[0][12];
          that.toward = data[0][13]; //朝向
          that.catid = data[0][14]; //物业类型
          that.typeid = data[0][16]; //出租还是出售
          that.houseArea = data[0][17]; //这个房屋的具体省市区县街道
          that.houseyear = data[0][18]; //房屋年龄
          that.floor1 = data[0][19]; //第几楼
          that.floor2 = data[0][20]; //共几层
          that.status = data[0][22]; //3上架  4下架
          that.fitup = data[0][24]; //装修

          that.keyword = data[0][27]; //下架原因

          //装修
          //浏览次数
        } else {
          //this.$router.push("login");
        }
      },
      async to_select_user() {
        //本渠道完成,需要先选择是卖给谁了
        this.$router.push({
          name: "marry",
        });

      },

      async on_sell() {
        //再次出售
        const param = {
          act: "updateHouseNotSell",
          id: this.id, //房屋id
          status: "3", //3正常销售  4 不想卖了  5售出
          note: "再次上架",
          user: Cookies.get("kh"),
          sitenum: this.$global.sitenum,
          token: Cookies.get("token"),
        };
        const url = this.$global.domain + "/tools/postmysql.ashx";
        axios.post(url, qs.stringify(param)).then((res) => {
          console.log(res.data);
          if (res.data.status == 1) {
            Toast(res.data.msg);
            //延时跳转 让顾客看清提示
            setTimeout(() => {
              //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
              //跳转
              this.$router.go(-1); //返回上一层

              //延迟时间：3秒
            }, 500);
          } else {
            //按理说, 不会出现!=1的情况
          }
        });
      },
      async not_sell(type) {
        let status = "4";
        let note = "";
        console.log('====================>' + type)
        switch (type) {
          case 41:
            //执行代码块 1
            status = "4";
            note = "不想租了";
            break;
          case 42:
            //执行代码块 1
            status = "4";
            note = "不想卖了";
            break;
          case 51:
            //执行代码块 2
            status = "5";
            note = "已租出";
            break;
          case 52:
            //执行代码块 2
            status = "5";
            note = "已售出";
            break;
          default:
            //与 case 1 和 case 2 不同时执行的代码

        }

        //当前房屋不卖了
        const param = {
          act: "updateHouseNotSell",
          id: this.id, //房屋id
          status: status, //3正常销售  4 不想卖了  5已售出
          note: note,
          user: Cookies.get("kh"),
          sitenum: this.$global.sitenum,
          token: Cookies.get("token"),
        };
        const url = this.$global.domain + "/tools/postmysql.ashx";
        axios.post(url, qs.stringify(param)).then((res) => {
          console.log(res.data);
          if (res.data.status == 1) {
            Toast(res.data.msg);
            //延时跳转 让顾客看清提示
            setTimeout(() => {
              //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
              //跳转
              this.$router.go(-1); //返回上一层

              //延迟时间：3秒
            }, 500);
          } else {
            //按理说, 不会出现!=1的情况
          }
        });
      },
    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .must_input {
    color: red;
  }

  .regin {
    .title {
      height: 68px;
      line-height: 68px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: #3d3d3d;
    }

    .line {
      height: 54px;
      line-height: 54px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      margin-bottom: 18px;
      font-size: 14px;
      text-align: left;
      color: #6e6e6e;

      // border: 1px red dashed;
      input {
        height: 50px;
        border: 1px #f2f2f2 solid;
        width: 344px;
        color: #464646;
        text-indent: 6rem;
      }

      .superIndent {
        text-indent: 120px;
      }

      .lable {
        text-indent: 12px;
        display: inline-block;
        // width: 76px;
        position: absolute;
      }

      .sexGroup {
        position: relative;
        // border: 1px red solid;
        width: 210px;
        margin-top: 17px;
        margin-left: 70px;
        display: inline-block;
      }

      .suojin {
        margin-left: 16px;
        left: 56px;
        position: relative;
      }

      .greenColor {
        position: relative;
        // border: 1px red solid;
        width: 280px;
        margin-top: 17px;
        margin-left: 30px;
        display: inline-block;

        i {
          color: #62c3ab;
          font-style: initial;
        }
      }
    }
  }

  .bottomBtn {
    position: relative;
    margin: 5px auto 10px auto;
    width: 354px;
    height: 54px;
    line-height: 54px;
    border-radius: 40px;
    background-color: #42bd56;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .backHome {
    height: 38px;
    width: 100%;
    line-height: 38px;
    // position: fixed;
    background-color: white;
    box-shadow: 2px 2px 6px #bfb8b8;
    display: inline-block;
    top: 0px;
    text-align: center;
    font-size: 16px;
    color: #222;
    z-index: 999;
    margin-bottom: 18px;

    span {
      position: absolute;
    }

    .backIco {
      left: 15px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/back2.png");
    }

    .backHomeIco {
      left: 43px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/home.png");
    }
  }
</style>
